<template>
  <div>
  <breadcrumb-nav>
    <template v-slot:firstMenu>订单管理</template>
    <template v-slot:secondMenu>退货申请</template>
  </breadcrumb-nav>
  <el-card class="filter-container" shadow="never">
    <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
      <el-form-item label="处理状态" prop="status">
        <el-select v-model="listQuery.status" style="width:120px">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请日期" prop="date">
        <el-date-picker
          v-model="listQuery.date"
          type="daterange"
          align="right"
          prop="date"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" border stripe>
      <el-table-column label="服务单号" prop="refundId"></el-table-column>
      <el-table-column label="申请时间" prop="createTime" width="200px" :formatter="formatDate">
      </el-table-column>
      <el-table-column label="申请人手机" prop="refundMobile"></el-table-column>
      <el-table-column label="申请状态" prop="status">
        <template slot-scope="scope">
          {{ scope.row.status | formatStatus }}
        </template>
      </el-table-column>
      <el-table-column label="申请类型" prop="refundType"></el-table-column>
      <el-table-column label="申请退款金额" prop="refundPrice">
        <template slot-scope="scope">
          {{ scope.row.applyPrice | formatFloat }}
        </template>
      </el-table-column>
      <el-table-column label="实际退款金额" prop="refundPrice">
        <template slot-scope="scope">
          {{ scope.row.refundPrice | formatFloat }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-view" size="mini"
                     @click="refundDetail(scope.row.refundId)">详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[5,10,20]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { GetApplyListRequest } from '../../../network/refund'

export default {
  name: 'RefundList',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      // 查询参数
      listQuery: {
        status: 0,
        date: '',
        page: 1,
        size: 10
      },
      // 状态列表
      // 1->待处理；2->退货中；3->退货；4->已拒绝；5->已取消
      statusOptions: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '待审核',
          value: 1
        },
        {
          label: '退货中',
          value: 2
        },
        {
          label: '已退款',
          value: 3
        },
        {
          label: '已拒绝',
          value: 4
        },
        {
          label: '已取消',
          value: 5
        }
      ],
      // 总数据条数
      total: 0,
      list: []
    }
  },
  filters: {
    formatStatus (value) {
      if (value === 1) {
        return '待审核'
      } else if (value === 2) {
        return '退货中'
      } else if (value === 3) {
        return '已退款'
      } else if (value === 4) {
        return '已拒绝'
      } else if (value === 5) {
        return '已取消'
      } else {
        return '未知'
      }
    },
    formatFloat (value) {
      return value.toFixed(2)
    }
  },
  mounted () {
    this.getApplyList(this.listQuery)
  },
  methods: {
    onSubmit () {
      this.getApplyList(this.listQuery)
    },
    resetForm () {
      this.$refs.listQuery.resetFields()
    },
    formatDate (row, column) {
      const value = row.createTime
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    },
    getApplyList (params) {
      const query = {
        page: params.page,
        size: params.size,
        status: params.status,
        isAdmin: 1
      }
      if (params.date !== null && params.date !== undefined && params.date.length > 1) {
        query.from = params.date[0]
        query.to = params.date[1]
      }
      GetApplyListRequest(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取退货申请列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    refundDetail (refundId) {
      this.$router.push({ path: '/refundDetail', query: { refundId: refundId } })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getApplyList(this.listQuery)
    },
    // 监听page的改变
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getApplyList(this.listQuery)
    }
  }
}
</script>

<style scoped>

</style>

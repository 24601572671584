import { request } from './request'

// 添加或更新地址
export function UpdateAddrRequest (addrInfo) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/addr/update',
    method: 'post',
    data: { ...addrInfo }
  })
}

// 获取地址列表
export function GetAddrListRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/addr/list/by/seller',
    method: 'get',
    params
  })
}

// 删除地址
export function DelAddrRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/addr/delete',
    method: 'post',
    data: { id: id }
  })
}

// 获取退货原因列表
export function GetReasonListRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/reason/list',
    method: 'get',
    params
  })
}

// 添加退货原因
export function AddReasonRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/reason/add',
    method: 'post',
    data: { ...info }
  })
}

// 修改退货原因
export function UpdateReasonRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/reason/update',
    method: 'post',
    data: { ...info }
  })
}

// 修改退货原因状态
export function UpdateReasonStatusRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/reason/status',
    method: 'post',
    data: { ...info }
  })
}

// 更改退款原因顺序
export function SortReasonRequest (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/reason/sort',
    method: 'post',
    data
  })
}

// 删除退款原因
export function DelReasonRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/reason/delete',
    method: 'post',
    data: { id: id }
  })
}

// 获取退货方式列表
export function GetOptionListRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/option/list',
    method: 'get',
    params
  })
}

// 添加退货方式
export function AddOptionRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/option/add',
    method: 'post',
    data: { ...info }
  })
}

// 修改退货方式
export function UpdateOptionRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/option/update',
    method: 'post',
    data: { ...info }
  })
}

// 修改退货原因状态
export function UpdateOptionStatusRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/option/status',
    method: 'post',
    data: { ...info }
  })
}

// 删除退款方式
export function DelOptionRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/option/delete',
    method: 'post',
    data: { id: id }
  })
}

// 更改退款原因顺序
export function SortOptionRequest (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/option/sort',
    method: 'post',
    data
  })
}

// 获取退货申请列表
export function GetApplyListRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/apply/list',
    method: 'get',
    params
  })
}

// 获取退货申请详情
export function GetApplyDetailRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/apply/detail',
    method: 'get',
    params
  })
}

// 更改退款原因顺序
export function UpdateRefundRequest (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/refund/apply/update',
    method: 'post',
    data
  })
}
